import request from '@/utils/request'

export function listSpecificationsGroup (query) {
  return request({
    url: '/shop/specifications_group/',
    method: 'get',
    params: query
  })
}

export function createSpecificationsGroup (parameter) {
  return request({
    url: '/shop/specifications_group/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function putSpecificationsGroup (parameter, id) {
  return request({
    url: '/shop/specifications_group/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function deleteSpecificationsGroup (id) {
  return request({
    url: '/shop/specifications_group/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function SpecificationsGroupGoods (query) {
  return request({
    url: '/shop/back_specifications_goods/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: query
  })
}

export function addSpecificationsGroupGoods (parameter) {
  return request({
    url: '/shop/back_specifications_goods/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function deleteSpecificationsGoods (parameter) {
  return request({
    url: '/shop/back_specifications_goods/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
