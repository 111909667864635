<template>
  <a-modal
    title="添加"
    :width="680"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="商品" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            placeholder="请输入商品名称"
            show-search
            allow-clear
            @search="goodsSearch"
            :filter-option="false"
            :not-found-content="fetching ? undefined : null"
            v-decorator="['id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
            @change="handleGroupGoodsChange"
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="item in goods" :value="item.id" :key="item.id">
              {{ item.brief }}（{{ item.barcode }}）
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>

import { listGoods } from '@/api/applet_goods'
import debounce from 'lodash/debounce'
export default {
  name: 'GoodsGroupCreate',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      fetching: false,
      goods: []
    }
  },
  created () {
    this.handleGroupGoodsChange(undefined)
  },
  methods: {
    goodsData (values) {
      this.fetching = true
      this.goods = []
      listGoods(values).then(res => {
          this.goods = res.data.entries
        }).finally(() => {
          this.fetching = false
      })
    },
    handleGroupGoodsChange (val) {
      console.log(val)
      if (val === undefined) {
        this.goodsData({ page: 1, page_size: 10 })
      }
    },
    goodsSearch: debounce(function (value) {
      this.goodsData({ brief: value })
    }, 500)
  }
}
</script>
